import { createApp } from 'vue'
import { createRouter, createWebHistory } from "vue-router";


import App from './App.vue'
import Homepage from './components/HelloWorld.vue'
import UsersList from './components/UsersList.vue'
import UserProfile from './components/UserProfile.vue'
import UserBets from './components/Bets.vue'
import Transactions from './components/Transactions.vue'
import Packages from './components/Packages.vue'

const routes = [
    { path: '/', component: Homepage },
    { path: '/users', component: UsersList },
    { path: '/transactions', component: Transactions },
    { path: '/packages', component: Packages },
    { path: '/user/:id', component: UserProfile },
    { path: '/bet/:id/:uid', component: UserBets }

]


const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    routes, // short for `routes: routes`
})


const app = createApp(App)
app.use(router);
app.mount('#app')