<template>
    <div class="content-wrapper">
        <div class="page-header">
            <h3 class="page-title"> Profile </h3>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">Sample Pages</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Profile</li>
                </ol>
            </nav>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="border-bottom text-center pb-4">
                                    <h3>Bet #{{userbet.id}}</h3>

                                </div>
                                <div class="border-bottom py-4">
                                    <div class="bet-card">
                                        <div class="bet-header">
                                            <div class="bet-info">
                                                <h3>Bet #{{userbet.id}}</h3>
                                                <p class="timestamp">{{userbet.time}}</p>
                                            </div>
                                            <div class="bet-status" :class="userbet.outcome">
                                                <span>{{userbet.outcome}}</span>
                                            </div>
                                        </div>

                                        <div class="bet-details">
                                            <div class="detail-item">
                                                <span class="label">Overall Odds:</span>
                                                <span class="value">{{userbet.odds}}</span>
                                            </div>
                                            <div class="detail-item">
                                                <span class="label">Stake:</span>
                                                <span class="value">{{userbet.currency}} {{userbet.stake}}</span>
                                            </div>
                                            <div class="detail-item">
                                                <span class="label">Posible Win:</span>
                                                <span class="value">{{userbet.currency}} {{userbet.winnings}}</span>
                                            </div>
                                            <div class="detail-item">
                                                <span class="label">Selections Won:</span>
                                                <span class="value">0/{{userbets.length}}</span>
                                            </div>
                                        </div>

                                        <div class="bet-share">
                                            <span>Share Code:</span>
                                            <span class="code">{{userbet.betcode}}</span>
                                            <button class="copy-btn">Copy</button>
                                        </div>
                                    </div>

                                </div>
                                <div class="border-bottom py-4">

                                   
                                           
                                            <div class="bet-card">
                                                <div class="bet-header">
                                                    <div class="bet-info">
                                                        <h3>Bet #{{userbet.id}}</h3>
                                                        <p class="timestamp">{{betType}}</p>
                                                    </div>
                                                    <div class="bet-status active">
                                                        <span>{{userbets.length}}</span>
                                                    </div>
                                                </div>

                                                <div class="bet-details">
                                                    <div class="detail-item">
                                                        <span class="label">Early Payout:</span>
                                                        <span class="value"><span v-if="userbet.isearlypayout === true">True</span><span v-else>False</span></span>
                                                    </div>
                                                    <div class="detail-item">
                                                        <span class="label">Bonus:</span>
                                                        <span class="value"><span v-if="userbet.isbonus === 0">True</span><span v-else>False</span></span>
                                                    </div>
                                                    <div class="detail-item">
                                                        <span class="label">Can Cashout:</span>
                                                        <span class="value"><span v-if="userbet.iscashout === true">True</span><span v-else>False</span></span>
                                                    </div>
                                                    <div class="detail-item">
                                                        <span class="label">Verify Code:</span>
                                                        <span class="value">{{userbet.verifycode}}</span>
                                                    </div>
                                                </div>

                                                <div class="bet-share">
                                                   
                                                    <div id="ddown7" class="dropdown b-dropdown btn-group"><!----><button id="ddown7__BV_toggle_" aria-haspopup="true" aria-expanded="false" type="button" class="btn dropdown-toggle btn-primary">Cashout</button><ul role="menu" tabindex="-1" aria-labelledby="ddown7__BV_toggle_" class="dropdown-menu" style=""><li role="presentation"><a role="menuitem" target="_self" href="#" class="dropdown-item">Settings</a></li><li role="presentation"><a role="menuitem" target="_self" href="#" class="dropdown-item">Action</a></li><li role="presentation"><a role="menuitem" target="_self" href="#" class="dropdown-item">Another action</a></li><li role="presentation"><hr role="separator" aria-orientation="horizontal" class="dropdown-divider"></li><li role="presentation"><a role="menuitem" target="_self" href="#" class="dropdown-item">Something else here...</a></li><li role="presentation"><a role="menuitem" target="_self" tabindex="-1" aria-disabled="true" href="#" class="dropdown-item disabled">Disabled action</a></li></ul></div>
                                                    <div id="ddown11" class="dropdown b-dropdown btn-group"><!----><button id="ddown11__BV_toggle_" aria-haspopup="true" aria-expanded="false" type="button" class="btn dropdown-toggle btn-outline-primary">Bet Options</button><ul role="menu" tabindex="-1" aria-labelledby="ddown11__BV_toggle_" class="dropdown-menu"><li role="presentation"><a role="menuitem" target="_self" href="#" class="dropdown-item">Settings</a></li><li role="presentation"><a role="menuitem" target="_self" href="#" class="dropdown-item">Action</a></li><li role="presentation"><a role="menuitem" target="_self" href="#" class="dropdown-item">Another action</a></li><li role="presentation"><hr role="separator" aria-orientation="horizontal" class="dropdown-divider"></li><li role="presentation"><a role="menuitem" target="_self" href="#" class="dropdown-item">Something else here...</a></li><li role="presentation"><a role="menuitem" target="_self" tabindex="-1" aria-disabled="true" href="#" class="dropdown-item disabled">Disabled action</a></li></ul></div> </div>
                                            </div>
                                        

                                </div>
                               
                            </div>
                            <div class="col-lg-8">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <ul class="nav profile-navbar">
                                            <li class="nav-item" @click="opencontainer('financials')">
                                                <a class="nav-link active financials" href="#">
                                                    <i class="mdi mdi-ticket"></i> Bet Details
                                                </a>
                                            </li>
                                            <li class="nav-item" @click="opencontainer('actions')">
                                                <a class="nav-link actions" href="#">
                                                    <i class="mdi mdi-cogs"></i> Actions
                                                </a>
                                            </li>
                                            <li class="nav-item" @click="opencontainer('profile')">
                                                <a class="nav-link profile" href="#">
                                                    <i class="mdi mdi-face-profile"></i> Profile
                                                </a>
                                            </li>

                                            <li class="nav-item" @click="opencontainer('transactions')">
                                                <a class="nav-link transactions" href="#">
                                                    <i class="mdi mdi-credit-card-clock"></i> Transactions
                                                </a>
                                            </li>
                                        </ul>
                                        <div class="d-flex align-items-center">


                                        </div>
                                    </div>

                                </div>

                                <div class="profile-feed">

                                    <div class="card data-container" id="financials">
                                        <div class="card-body">


                                            <div class="selections-container">
                                                <!-- First Match -->
                                                <div class="selection-card" v-for="bet in userbets" :key="index">
                                                    <div class="header">
                                                        <div class="sport-country">
                                                            <span class="sport">⚽ Football</span>
                                                            <span class="country">{{bet.country}}</span>
                                                            <span class="status live">Live</span>
                                                        </div>
                                                        <span class="league">{{bet.league}}</span>
                                                    </div>
                                                    <div class="match-details">
                                                        <div class="teams">
                                                            <span class="team">{{bet.home}}</span>
                                                            <span class="vs">vs</span>
                                                            <span class="team">{{bet.away}}</span>
                                                        </div>
                                                        <div class="status-and-time">
                                                            <span class="start-time">{{bet.time}}</span>

                                                        </div>
                                                    </div>
                                                    <div class="score-section">
                                                        <span></span>
                                                        <span class="score">{{bet.home_score}} - {{bet.away_score}}</span>
                                                    </div>
                                                    <div class="bet-info">
                                                        <div class="pick">
                                                            <strong>Pick:</strong> {{bet.pick}} @{{bet.odd}}
                                                        </div>
                                                        <div class="market">
                                                            <strong>Market:</strong> {{bet.market}}
                                                        </div>
                                                        <div class="market">
                                                            <strong>Status:</strong> <span>{{bet.gamestatus}}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- Second Match -->

                                            </div>





                                        </div>
                                    </div>
                                    <div class="card data-container" id="actions" style="display:none">
                                        <div class="card-body">
                                            <div class="bet-card">
                                                <div class="bet-header">
                                                    <div class="bet-info">
                                                        <h3>Bet #{{userbet.id}}</h3>
                                                        <p class="timestamp">{{betType}}</p>
                                                    </div>
                                                    <div class="bet-status active">
                                                        <span>{{userbets.length}}</span>
                                                    </div>
                                                </div>

                                                <div class="bet-details">
                                                    <div class="detail-item">
                                                        <button type="button" @click="settleWon()" class="btn btn-md btn-block btn-success">Settle All Won <i class="mdi mdi-checkbox-marked-circle-outline float-right"></i></button>

                                                        <button type="button" class="btn btn-md btn-block btn-danger">Settle All Lost <i class="mdi mdi-alert-circle-outline float-right"></i></button>
                                                        <button type="button" class="btn btn-md btn-block btn-warning">Void <i class="mdi mdi-restart float-right"></i></button>


                                                    </div>


                                                </div>

                                                <div class="bet-share">

                                                    <div id="ddown7" class="dropdown b-dropdown btn-group"><!----><button id="ddown7__BV_toggle_" aria-haspopup="true" aria-expanded="false" type="button" class="btn dropdown-toggle btn-primary">Cashout</button><ul role="menu" tabindex="-1" aria-labelledby="ddown7__BV_toggle_" class="dropdown-menu" style=""><li role="presentation"><a role="menuitem" target="_self" href="#" class="dropdown-item">Settings</a></li><li role="presentation"><a role="menuitem" target="_self" href="#" class="dropdown-item">Action</a></li><li role="presentation"><a role="menuitem" target="_self" href="#" class="dropdown-item">Another action</a></li><li role="presentation"><hr role="separator" aria-orientation="horizontal" class="dropdown-divider"></li><li role="presentation"><a role="menuitem" target="_self" href="#" class="dropdown-item">Something else here...</a></li><li role="presentation"><a role="menuitem" target="_self" tabindex="-1" aria-disabled="true" href="#" class="dropdown-item disabled">Disabled action</a></li></ul></div>
                                                    <div id="ddown11" class="dropdown b-dropdown btn-group"><!----><button id="ddown11__BV_toggle_" aria-haspopup="true" aria-expanded="false" type="button" class="btn dropdown-toggle btn-outline-primary">Bet Options</button><ul role="menu" tabindex="-1" aria-labelledby="ddown11__BV_toggle_" class="dropdown-menu"><li role="presentation"><a role="menuitem" target="_self" href="#" class="dropdown-item">Settings</a></li><li role="presentation"><a role="menuitem" target="_self" href="#" class="dropdown-item">Action</a></li><li role="presentation"><a role="menuitem" target="_self" href="#" class="dropdown-item">Another action</a></li><li role="presentation"><hr role="separator" aria-orientation="horizontal" class="dropdown-divider"></li><li role="presentation"><a role="menuitem" target="_self" href="#" class="dropdown-item">Something else here...</a></li><li role="presentation"><a role="menuitem" target="_self" tabindex="-1" aria-disabled="true" href="#" class="dropdown-item disabled">Disabled action</a></li></ul></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="bet-card" v-for="(bet, index) in userbets" :key="index" style="margin-top:10px">
                                                <div class="bet-header">
                                                    <div class="bet-info">
                                                        <h3>{{bet.home}} vs {{bet.away}} </h3>
                                                        <p class="timestamp">{{bet.score}}</p>
                                                        <div class="pick">
                                                            <strong>Pick:</strong> {{bet.pick}} @{{bet.odd}}
                                                        </div>
                                                    </div>
                                                    <div class="bet-status" :class="bet.gamestatus">
                                                        <span>{{bet.gamestatus}}</span>
                                                    </div>
                                                </div>

                                                <div class="bet-details">
                                                    <div class="detail-item c">
                                                        <button type="button" @click="settlegame(bet.parent_id,'Won')" class="btn btn-md btn-block btn-success">Won <i class="mdi mdi-checkbox-marked-circle-outline float-right"></i></button>

                                                        <button type="button" @click="settlegame(bet.parent_id,'Lost')" class="btn btn-md btn-block btn-danger">Lost <i class="mdi mdi-alert-circle-outline float-right"></i></button>
                                                        <button type="button" @click="settlegame(bet.parent_id,'Void')" class="btn btn-md btn-block btn-warning">Void <i class="mdi mdi-restart float-right"></i></button>


                                                    </div>


                                                </div>

                                                <div class="bet-share">
                                                    <input v-model="scores[index]"   type="text" placeholder="Score" class="" style="width:250px">
                                                    <button @click="updatescore(index,bet.parent_id)" :disabled="index !== targetIndex || scores[index]?.length < 1"  type="button" class="btn btn-md btn-block btn-primary">Update Score <i class="mdi mdi-soccer-field float-right"></i></button>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                               
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade show" id="exampleModal-4" tabindex="-1" aria-labelledby="ModalLabel" style="display: none" aria-modal="true" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="ModalLabel">New message to @mdo</h5>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="form-group">
                            <label for="recipient-name" class="col-form-label">Recipient:</label>
                            <input type="text" class="form-control" id="recipient-name">
                        </div>
                        <div class="form-group">
                            <label for="message-text" class="col-form-label">Message:</label>
                            <textarea class="form-control" id="message-text"></textarea>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-success">Send message</button>
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DataTable from 'datatables.net-vue3';
    import DataTablesCore from 'datatables.net';
    import axios from 'axios'
    import Buttons from 'datatables.net-buttons';
    import $ from 'jquery';
    import Swal from 'sweetalert2'

    DataTable.use(DataTablesCore);
    DataTable.use(Buttons);

    // Load DataTables core
    //  DataTablesCore(window, $);
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    export default {
        components: {
            DataTable,
        },
        data() {
            return {
                order: [
                    [2, "desc"],

                ],
                columns: [

                    { data: 'tid' },
                    { data: 'date' },
                    { data: 'amount' },
                    { data: 'narration' },
                    { data: 'method' },
                    { data: 'status' },
                    { data: 'message' },
                    { data: 'wallet' },
                    {
                        data: 'status',
                        render: function (data, type) {
                            if (type === 'display') {
                                if (data == 'Pending') { return `<div  @click="reject(${tid})" class="badge badge-outline-warning">Active</div>`; }
                                else if (data == 'Completed') { return `<div  @click="reject(${tid})" class="badge badge-outline-success">Won</div>`; }
                                else if (data == 'Rejected') { return `<div @click="reject(${tid})"  class="badge badge-outline-danger">Lost</div>`; }

                                // Create a clickable cell using the renderer

                            }
                            return data;
                        }
                    },


                ],
                columns2: [
                    { data: 'id' },
                    { data: 'timeg' },
                    { data: 'odds' },
                    { data: 'stake' },
                    { data: 'winnings' },

                    {
                        data: 'outcomeg',
                        render: function (data, type) {
                            if (type === 'display') {
                                if (data == 'Active') { return `<div class="badge badge-outline-warning">Active</div>`; }
                                else if (data == 'Won') { return `<div class="badge badge-outline-success">Won</div>`; }
                                else if (data == 'Lost') { return `<div class="badge badge-outline-danger">Lost</div>`; }

                                // Create a clickable cell using the renderer

                            }
                            return data;
                        }
                    },
                    {
                        data: 'id',
                        render: function (data, type) {
                            if (type === 'display') {
                                // Create a clickable cell using the renderer
                                return `<router-link :to="'/bets' + ${data}" onclick="this.$router.push('/')" class="badge badge-outline-success">Settle</router-link>`;
                            }
                            return data;
                        }
                    }

                ],
                userinfo: [],
                userbet:[],
                betdata: [],
                username: null,
                userbets: [],
                score: [],
                scores: [], // Array to hold scores for each bet
                targetIndex: 0,
                url3: '',
                urls: '',
                url: 'https://posetbet.com/api/v3/usertransactions?id=' + this.$route.params.id,
                url2: 'https://posetbet.com/api/v3/userbets?id=' + this.username

            };

        }
        ,
        methods: {
            handleCellClick(userId,bid) {
                // Handle the cell click event using Vue Router's push method
                this.$router.push({ path: `/bet/${bid}/${userId}` });
            },
            handleRowClick() {
                console.log('h')
            },
            opencontainer(id) {
                $('.data-container').hide();
                $('#' + id).show();
                $('.nav-link').removeClass('active');
                $('.' + id).addClass('active');

            },
            async submitForm() {
                const action = $('#action').val();
                $('#btncredit').attr('disabled');
                const amount = $('#amount').val();
                const uid = this.userinfo.ID;
                const gateway = $('#gateway').val();
                const tid = $('#tid').val();
                const narration = $('#narration').val();
                const currency = $('#currency').val();
                const balance = $('#balance').val();

                const formData = new URLSearchParams();
                formData.append('action', action);
                formData.append('amount', amount);
                formData.append('uid', uid);
                formData.append('balance', balance);
                formData.append('currency', currency);
                formData.append('gateway', gateway);
                formData.append('tid', tid);
                formData.append('narration', narration);

                try {
                    const response = await fetch('https://posetbet.com/api/v3/adjustbalances', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        body: formData
                    });

                    if (response.ok) {
                        $('#btncredit').removeAttr('disabled');
                        // Handle successful response
                        console.log('Form submitted successfully');
                        this.getuser();
                        this.getusertransactions();
                        Toast.fire({
                            icon: 'success',
                            title: 'Transaction Registered '
                        })
                    } else {
                        $('#btncredit').removeAttr('disabled');
                        // Handle error response
                        console.error('Error submitting form');
                    }
                } catch (error) {
                    console.error('An error occurred:', error);
                    $('#btncredit').removeAttr('disabled');
                }
            }
            ,
            getuser() {
                axios.get(`https://posetbet.com/api/v3/user`, {
                    params: { id: this.$route.params.uid }
                })
                    .then(response => {
                        this.userinfo = response.data;
                        this.username = response.data.username;
                        this.urls = 'https://posetbet.com/api/v3/userbets?id=' + response.data.username;
                        console.log(this.url3);
                    })
                    .catch(error => {
                        console.error('Error fetching user:', error);
                    });
            },
            getbet() {
                // Optionally, you can show a loading state before making the request
                this.loading = true;

                axios.post(`https://posetbet.com/api/v3/sudo/bets`, {
                    id: parseInt(this.$route.params.id),
                    uid: parseInt(this.$route.params.uid)
                }, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })
                    .then(response => {
                        // Store the response data in the component's state
                        this.userbet = response.data;

                        // Optionally, handle if no bets are returned
                        if (!this.userbet.length) {
                            console.warn('No bets found for this user.');
                        }
                    })
                    .catch(error => {
                        console.error('Error fetching user bets:', error);
                        // Optionally, handle the error more gracefully
                        this.error = 'An error occurred while fetching the bets.';
                    })
                    .finally(() => {
                        // Hide the loading state after the request completes
                        this.loading = false;
                    });
            },
            getbetdata() {
                // Optionally, you can show a loading state before making the request
                this.loading = true;

                axios.post(`https://posetbet.com/api/v3/sudo/getbetdata`, {
                    id: parseInt(this.$route.params.id),
                    uid: parseInt(this.$route.params.uid)
                }, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })
                    .then(response => {
                        // Store the response data in the component's state
                        this.betdata = response.data;

                        // Optionally, handle if no bets are returned
                        if (!this.betdata.length) {
                            console.warn('No bets found for this user.');
                        }
                    })
                    .catch(error => {
                        console.error('Error fetching user bets:', error);
                        // Optionally, handle the error more gracefully
                        this.error = 'An error occurred while fetching the bets.';
                    })
                    .finally(() => {
                        // Hide the loading state after the request completes
                        this.loading = false;
                    });
            },
            getbets() {
                // Optionally, you can show a loading state before making the request
                this.loading = true;

                axios.post(`https://posetbet.com/api/v3/betdetails`, {
                    id: parseInt(this.$route.params.id),
                    uid: parseInt(this.$route.params.uid)
                }, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })
                    .then(response => {
                        // Store the response data in the component's state
                        this.userbets = response.data;

                        // Optionally, handle if no bets are returned
                        if (!this.userbets.length) {
                            console.warn('No bets found for this user.');
                        }
                    })
                    .catch(error => {
                        console.error('Error fetching user bets:', error);
                        // Optionally, handle the error more gracefully
                        this.error = 'An error occurred while fetching the bets.';
                    })
                    .finally(() => {
                        // Hide the loading state after the request completes
                        this.loading = false;
                    });
            },
           settleWon(id,uid) {
                // Optionally, you can show a loading state before making the request
                this.loading = true;

                axios.post(`https://posetbet.com/api/v3/sudo/settlebet`, {
                    id: parseInt(this.$route.params.id),
                    uid: parseInt(this.$route.params.uid),
                    outcome:'Won'
                }, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })
                    .then(response => {
                        // Store the response data in the component's state
                        this.settlestatus = response.data;

                        // Optionally, handle if no bets are returned
                        if (!this.userbets.length) {
                            console.warn('No bets found for this user.');
                        }
                    })
                    .catch(error => {
                        console.error('Error fetching user bets:', error);
                        // Optionally, handle the error more gracefully
                        this.error = 'An error occurred while fetching the bets.';
                    })
                    .finally(() => {
                        // Hide the loading state after the request completes
                        this.loading = false;
                    });
            },
            settleLost(id, uid) {
                // Optionally, you can show a loading state before making the request
                this.loading = true;

                axios.post(`https://posetbet.com/api/v3/sudo/settleloss`, {
                    id: parseInt(this.$route.params.id),
                    uid: parseInt(this.$route.params.uid),
                    outcome: 'Lost'
                }, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })
                    .then(response => {
                        // Store the response data in the component's state
                        this.settlestatus = response.data;

                        // Optionally, handle if no bets are returned
                        if (!this.userbets.length) {
                            console.warn('No bets found for this user.');
                        }
                    })
                    .catch(error => {
                        console.error('Error fetching user bets:', error);
                        // Optionally, handle the error more gracefully
                        this.error = 'An error occurred while fetching the bets.';
                    })
                    .finally(() => {
                        // Hide the loading state after the request completes
                        this.loading = false;
                    });
            },
            settleVoid(id, uid) {
                // Optionally, you can show a loading state before making the request
                this.loading = true;

                axios.post(`https://posetbet.com/api/v3/sudo/settlevoid`, {
                    id: parseInt(this.$route.params.id),
                    uid: parseInt(this.$route.params.uid),
                    outcome: 'Void'
                }, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })
                    .then(response => {
                        // Store the response data in the component's state
                        this.settlestatus = response.data;

                        // Optionally, handle if no bets are returned
                        if (!this.userbets.length) {
                            console.warn('No bets found for this user.');
                        }
                    })
                    .catch(error => {
                        console.error('Error fetching user bets:', error);
                        // Optionally, handle the error more gracefully
                        this.error = 'An error occurred while fetching the bets.';
                    })
                    .finally(() => {
                        // Hide the loading state after the request completes
                        this.loading = false;
                    });
            },
            settlegame(pid, outcome) {
                // Optionally, you can show a loading state before making the request
                this.loading = true;

                axios.post(`https://posetbet.com/api/v3/sudo/settlegame`, {
                    id: parseInt(this.$route.params.id),
                    uid: parseInt(this.$route.params.uid),
                    outcome: outcome,
                    parent_id:pid
                   
                }, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })
                    .then(response => {
                        // Store the response data in the component's state
                        this.settlestatus = response.data;

                        // Optionally, handle if no bets are returned
                        if (!this.userbets.length) {
                            console.warn('No bets found for this user.');
                        }
                    })
                    .catch(error => {
                        console.error('Error fetching user bets:', error);
                        // Optionally, handle the error more gracefully
                        this.error = 'An error occurred while fetching the bets.';
                    })
                    .finally(() => {
                        // Hide the loading state after the request completes
                        this.loading = false;
                        this.getbets();
                        this.getbet();
                    });
            },
            updatescore(index, pid) {
                // Optionally, you can show a loading state before making the request
                this.loading = true;

                axios.post(`https://posetbet.com/api/v3/sudo/updatescore`, {
                    score: this.scores[index],
                   
                    parent_id: pid

                }, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })
                    .then(response => {
                        // Store the response data in the component's state
                        this.settlestatus = response.data;

                        // Optionally, handle if no bets are returned
                        if (!this.userbets.length) {
                            console.warn('No bets found for this user.');
                        }
                    })
                    .catch(error => {
                        console.error('Error fetching user bets:', error);
                        // Optionally, handle the error more gracefully
                        this.error = 'An error occurred while fetching the bets.';
                    })
                    .finally(() => {
                        // Hide the loading state after the request completes
                        this.loading = false;
                        this.getbets();
                        this.getbet();
                    });
            }

        },

        created() {

            this.getuser();
            this.getbet();
            this.getbets();
           // this.getbetdata();

            const self = this;

            // Add click event listener to the table for the 'cell-click' class
            this.$nextTick(() => {
                console.log('called')
                const table = $('#tableusers'); // Replace with your actual DataTable element selector
                table.on('click', '.cell-click', function () {
                    const userId = $(this).data('id');
                    self.handleCellClick(userId);
                });
            });
        },
        computed: {
            betType() {
                const betCount = this.userbets.length;

                if (betCount === 1) {
                    return 'Single';
                } else if (betCount === 2) {
                    return 'Double';
                } else if (betCount === 3) {
                    return 'Triple';
                } else if (betCount === 4) {
                    return 'Four-fold';
                } else if (betCount === 5) {
                    return 'Five-fold';
                } else if (betCount === 6) {
                    return 'Six-fold';
                } else {
                    return `${betCount}-fold`;
                }
            }
        }

    }
</script>

<style>
    .profile-navbar .nav-item .nav-link {
        color: #ffffff;
    }

        .profile-navbar .nav-item .nav-link.active {
            color: #0090e7;
        }
    
    .selections-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px;
        background: #f9f9f9;
        border-radius:5px;
    }

    .selection-card {
        background: #ffffff;
        border: 1px solid #e5e5e5;
        border-radius: 10px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #f0f0f0;
        padding-bottom: 10px;
    }

    .sport-country {
        display: flex;
        gap: 10px;
        font-size: 14px;
        font-weight: bold;
        color: #555;
    }

    .league {
        font-size: 14px;
        color: #777;
    }

    .match-details {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .teams {
        font-size: 16px;
        font-weight: bold;
        color: #333;
        text-align: center;
    }

    .vs {
        margin: 0 8px;
        color: #888;
    }

    .status-and-time {
        display: flex;
        justify-content: center;
        font-size: 14px;
        color: #666;
    }

    .status {
        font-weight: bold;
        padding: 4px 8px;
        border-radius: 5px;
    }

        .status.live {
            background: #28a745;
            color: white;
        }

        .status.ended {
            background: #dc3545;
            color: white;
        }

    .score-section {
        background: #f3f3f3; /* Light background for contrast */
        color: #333333; /* White text for visibility */
        padding: 10px;
        border-radius: 5px;
        text-align: center;
        font-size: 18px; /* Slightly larger font for better readability */
        font-weight: bold;
    }

    .bet-info {
        display: flex;
        flex-direction: column;
        gap: 8px;
        font-size: 14px;
        color: #555;
    }

    .pick strong, .market strong {
        color: #333;
    }
    .start-times {
        display: flex;
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically */
        background: #f3f3f3; /* Light background for contrast */
        color: #333333; /* Dark text for readability */
        font-size: 14px;
        font-weight: 500;
        padding: 8px;
        border-radius: 5px;
        text-align: center;
    }
    .bet-card {
        background: #ffffff;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        padding: 16px;
        max-width: 500px;
        margin: auto;
        font-family: Arial, sans-serif;
    }

    .bet-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e6e6e6;
        padding-bottom: 12px;
        margin-bottom: 12px;
    }

    .bet-info h3 {
        font-size: 18px;
        margin: 0;
        color: #333333;
    }

    .bet-info .timestamp {
        font-size: 14px;
        color: #777777;
    }

    .bet-status {
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
        background: #28a745;
        padding: 6px 12px;
        border-radius: 16px;
    }

        .bet-status.active {
            background: #007bff;
        }

    .bet-details {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 12px;
    }

    .detail-item {
        display: flex;
        justify-content: space-between;
    }

    .label {
        font-size: 14px;
        color: #777777;
    }

    .value {
        font-size: 14px;
        color: #333333;
    }

    .bet-share {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #e6e6e6;
        padding-top: 12px;
    }

        .bet-share .code {
            font-size: 14px;
            font-weight: bold;
            color: #555555;
        }

    .copy-btn {
        background: #007bff;
        color: #ffffff;
        border: none;
        padding: 6px 12px;
        border-radius: 4px;
        cursor: pointer;
    }

        .copy-btn:hover {
            background: #0056b3;
        }


</style>