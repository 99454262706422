<template>
    <div class="content-wrapper">
        <div class="page-header">
            <h3 class="page-title"> Profile </h3>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">Sample Pages</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Profile</li>
                </ol>
            </nav>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="border-bottom text-center pb-4">
                                    <img src="https://i0.wp.com/studiolorier.com/wp-content/uploads/2018/10/Profile-Round-Sander-Lorier.jpg?ssl=1" alt="profile" class="img-lg rounded-circle mb-3">
                                    <p>{{userinfo.address1}} , {{userinfo.town}} ,{{userinfo.city}}, {{userinfo.county}}, {{userinfo.Country}}. </p>
                                    <div class="d-flex justify-content-between">
                                        <button class="btn btn-success">Active</button>
                                        <button class="btn btn-success">Verified</button>
                                    </div>
                                </div>
                                <div class="border-bottom py-4">
                                    <p>Verification</p>
                                    <div>
                                        <label class="badge"
                                               :class="{
        'badge-outline-success': userinfo.isemailverify == 1,
        'badge-outline-danger': userinfo.isemailverify == 0
      }"
                                               style="margin: 5px;">
                                            Email
                                        </label>
                                        <label class="badge" :class="{
        'badge-outline-success': userinfo.isphoneverify == 1,
        'badge-outline-danger': userinfo.isphoneverify === 0
      }" style="margin: 5px;"> Phone Number </label>
                                        <label class="badge" :class="{
        'badge-outline-warning': userinfo.id_verified == 1 | 3,
        'badge-outline-danger': userinfo.id_verified == 0,
        'badge-outline-success': userinfo.id_verified == 5,
       

      }" style="margin: 5px;">ID </label>
                                        <label class="badge" :class="{
        
        'badge-outline-warning': userinfo.address_verified == 1,
        'badge-outline-danger': userinfo.address_verified == 0,
         'badge-outline-warning': userinfo.address_verified == 3,
         'badge-outline-success': userinfo.address_verified == 5,
      }" style="margin: 5px;">Address</label>
                                        <label class="badge" :class="{
        'badge-outline-success': userinfo.status  == 1,
        'badge-outline-danger': userinfo.status  == 0
      }" style="margin: 5px;">Withdrawal </label>
                                    </div>
                                </div>
                                <div class="border-bottom py-4">

                                    <div class="card">
                                        <div class="card-body">
                                            <h4 class="card-title mb-0">Balance</h4>
                                            <div class="d-flex justify-content-between align-items-center flex-wrap">
                                                <div class="d-inline-block pt-3">
                                                    <div class="d-flex">
                                                        <h2 class="mb-0"> {{userinfo.currency}} {{userinfo.balance}}</h2>

                                                    </div>
                                                    <small class="text-gray">Raised from 89 orders.</small>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="py-4">
                                    <p class="clearfix">
                                        <span class="float-left"> Status </span>
                                        <span class="float-right text-muted"> {{userinfo.status}} </span>
                                    </p>
                                    <p class="clearfix">
                                        <span class="float-left"> Phone </span>
                                        <span class="float-right text-muted">  +{{userinfo.dialcode}}{{userinfo.PhoneNumber}} </span>
                                    </p>
                                    <p class="clearfix">
                                        <span class="float-left"> Email </span>
                                        <span class="float-right text-muted">  {{userinfo.Email}} </span>
                                    </p>
                                    <p class="clearfix">
                                        <span class="float-left"> Date of Birth </span>
                                        <span class="float-right text-muted">
                                            {{userinfo.day}}/{{userinfo.month}}/{{userinfo.year}}
                                        </span>
                                    </p>
                                    <p class="clearfix">
                                        <span class="float-left"> Twitter </span>
                                        <span class="float-right text-muted">
                                            <a href="#">@davidgrey</a>
                                        </span>
                                    </p>
                                </div>
                                <button class="btn btn-primary btn-block">Preview</button>
                            </div>
                            <div class="col-lg-8">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <h3>{{userinfo.FirstName}}  {{userinfo.LastName}} </h3>
                                        <div class="d-flex align-items-center">
                                            <h5 class="mb-0 me-2 text-muted">{{userinfo.username}}</h5>
                                            <div class="br-wrapper br-theme-css-stars">
                                                <select id="profile-rating" name="rating" autocomplete="off" style="display: none;">
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                </select>
                                                <div class="br-widget"><a href="#" data-rating-value="1" data-rating-text="1" class="br-selected br-current"></a><a href="#" data-rating-value="2" data-rating-text="2"></a><a href="#" data-rating-value="3" data-rating-text="3"></a><a href="#" data-rating-value="4" data-rating-text="4"></a><a href="#" data-rating-value="5" data-rating-text="5"></a></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <button class="btn btn-outline-secondary btn-icon">
                                            <i class="mdi mdi-comment-processing"></i>
                                        </button>
                                        <button class="btn btn-primary">Request</button>
                                    </div>
                                </div>
                                <div class="mt-4 py-2 border-top border-bottom">
                                    <ul class="nav profile-navbar">
                                        <li class="nav-item" @click="opencontainer('financials')">
                                            <a class="nav-link active financials" href="#">
                                                <i class="mdi mdi-currency-usd"></i> Financials
                                            </a>
                                        </li>
                                        <li class="nav-item" @click="opencontainer('bets')">
                                            <a class="nav-link bets" href="#">
                                                <i class="mdi mdi-ticket"></i> Bets
                                            </a>
                                        </li>
                                        <li class="nav-item" @click="opencontainer('profile')">
                                            <a class="nav-link profile" href="#">
                                                <i class="mdi mdi-face-profile"></i> Profile
                                            </a>
                                        </li>
                                        <li class="nav-item" @click="opencontainer('communication')">
                                            <a class="nav-link communication" href="#">
                                                <i class="mdi mdi-attachment"></i> Communication
                                            </a>
                                        </li>
                                        <li class="nav-item" @click="opencontainer('transactions')">
                                            <a class="nav-link transactions" href="#">
                                                <i class="mdi mdi-credit-card-clock"></i> Transactions
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="profile-feed">

                                    <div class="card data-container" id="financials">
                                        <div class="card-body">

                                            <p class="card-description"> Edit User Balance </p>
                                            <form class="forms-sample" id="formsx" @submit.prevent="submitForm">
                                                <div class="form-group">
                                                    <label>Action</label>
                                                    <select class="js-example-basic-single select2-container" id="action" style="width:100%;background-color: #2a3038;border-color: aquamarine;border: 1px solid #2c2e33;height: calc(2.25rem + 2px);font-weight: normal;font-size: 0.875rem;padding: 0.625rem 0.6875rem;background-color: #2A3038;border-radius: 2px;color: #ffffff;" data-select2-id="1" tabindex="-1" aria-hidden="true" name="state">
                                                        <option value="Credit">Credit</option>
                                                        ...
                                                        <option value="Debit">Debit</option>
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <label>Currency Type</label>
                                                    <select class="js-example-basic-single select2-container" id="balance" style="width:100%;background-color: #2a3038;border-color: aquamarine;border: 1px solid #2c2e33;height: calc(2.25rem + 2px);font-weight: normal;font-size: 0.875rem;padding: 0.625rem 0.6875rem;background-color: #2A3038;border-radius: 2px;color: #ffffff;" data-select2-id="1" tabindex="-1" aria-hidden="true" name="state">
                                                        <option value="Fiat">Fiat</option>
                                                        ...
                                                        <option value="Crypto">Crypto</option>
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">Amount</label>
                                                    <input type="number" step="any" class="form-control" id="amount" placeholder="Amount" required>
                                                    <input type="hidden" class="form-control" id="uid" :value="userinfo.ID">
                                                </div>
                                                <div class="form-group">
                                                    <label for="exampleInputPassword1">Gateway</label>
                                                    <input type="text" class="form-control" id="gateway" placeholder="Gateway" required>
                                                </div>
                                                <div class="form-group">
                                                    <label for="exampleInputPassword1">Currency</label>
                                                    <input type="text" class="form-control" id="currency" placeholder="Currency" required>
                                                </div>
                                                <div class="form-group">
                                                    <label for="exampleInputConfirmPassword1">Transaction ID</label>
                                                    <input type="text" class="form-control" id="tid" placeholder="TID" required>
                                                </div>
                                                <div class="form-group">
                                                    <label for="exampleInputConfirmPassword1">Narration</label>
                                                    <input type="text" class="form-control" id="narration" placeholder="narration" required>
                                                </div>

                                                <button type="submit" id="btncredit" class="btn btn-primary me-2">Submit</button>

                                            </form>
                                        </div>
                                    </div>
                                    <div class="card data-container" id="profile">
                                        <div class="card-body">

                                            <p class="card-description"> Edit User Profile</p>
                                            <form class="forms-sample">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">First Name</label>
                                                    <input type="text" class="form-control" id="firstname" :value="userinfo.FirstName">
                                                </div>
                                                <div class="form-group">
                                                    <label for="exampleInputPassword1">Last Name</label>
                                                    <input type="text" class="form-control" id="lastname" :value="userinfo.LastName">
                                                </div>
                                                <div class="form-group">
                                                    <label for="exampleInputConfirmPassword1">Email</label>
                                                    <input type="email" class="form-control" id="email" :value="userinfo.Email">
                                                </div>
                                                <div class="form-group">
                                                    <label for="exampleInputConfirmPassword1">Username</label>
                                                    <input type="text" class="form-control" id="username" :value="userinfo.username">
                                                </div>
                                                <div class="form-group">
                                                    <label for="exampleInputConfirmPassword1">Phone Number</label>
                                                    <input type="text" class="form-control" id="phonenumber" :value="userinfo.PhoneNumber">
                                                </div>
                                                <div class="form-group">

                                                    <label for="exampleInputConfirmPassword1">Currency</label>
                                                    <input type="text" class="form-control" id="currency" :value="userinfo.currency">
                                                </div>
                                                <div class="form-group">
                                                    <label for="exampleInputConfirmPassword1">Year of Birth</label>
                                                    <input type="text" class="form-control" id="year" :value="userinfo.year">
                                                </div>
                                                <div class="form-group">
                                                    <label for="exampleInputConfirmPassword1">Month</label>
                                                    <input type="text" class="form-control" id="month" :value="userinfo.month">
                                                </div>
                                                <div class="form-group">
                                                    <label for="exampleInputConfirmPassword1">Day</label>
                                                    <input type="text" class="form-control" id="day" :value="userinfo.day">
                                                </div>
                                                <button type="submit" class="btn btn-primary me-2">Submit</button>
                                            </form>

                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="card data-container" id="bets" style="display:none">
                                            <div class="card-body">
                                                <h4 class="card-title">Transactions</h4>
                                                <div class="table-responsive">
                                                    <DataTable :columns="columns2" id="tablebets"
                                                               :options="tableOptions"
                                                               :ajax="'https://posetbet.com/api/v3/sudo/userbets?id=' + this.$route.params.id"
                                                               class="display"
                                                               width="100%">
                                                        <thead>
                                                            <tr>
                                                                <th>ID</th>
                                                                <th>Date</th>
                                                                <th>Odds</th>
                                                                <th>Stake</th>
                                                                <th>Winnings</th>
                                                                <th>Outcome</th>
                                                                <th>Action</th>

                                                            </tr>
                                                        </thead>
                                                        <tfoot>
                                                            <tr>

                                                                <th>ID</th>
                                                                <th>Date</th>
                                                                <th>Odds</th>
                                                                <th>Stake</th>
                                                                <th>Winnings</th>
                                                                <th>Outcome</th>
                                                                <th>Action</th>

                                                            </tr>
                                                        </tfoot>
                                                    </DataTable>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="card data-container" id="transactions" style="display:none">
                                            <div class="card-body">
                                                <h4 class="card-title">Transactions</h4>
                                                <div class="table-responsive">
                                                    <DataTable :columns="columns" id="tableuserstrans"
                                                               :ajax="url"
                                                               :options="{order: [[1, 'desc']]}"
                                                               class="display"
                                                               width="100%" @row-click="handleRowClick">
                                                        <thead>
                                                            <tr>
                                                                <th>ID</th>
                                                                <th>Date</th>
                                                                <th>Amount</th>
                                                                <th>Narration</th>
                                                                <th>Method</th>
                                                                <th>Status</th>
                                                                <th>Action</th>
                                                                <th>Actione</th>
                                                                <th>Actiones</th>

                                                            </tr>
                                                        </thead>
                                                        <tfoot>
                                                            <tr>
                                                                <th>ID</th>
                                                                <th>Date</th>
                                                                <th>Amount</th>
                                                                <th>Narration</th>
                                                                <th>Method</th>
                                                                <th>Status</th>
                                                                <th>Action</th>
                                                                <th>Actione</th>
                                                                <th>Actiones</th>
                                                            </tr>
                                                        </tfoot>
                                                    </DataTable>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade show" id="exampleModal-4" tabindex="-1" aria-labelledby="ModalLabel" style="display: none" aria-modal="true" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="ModalLabel">New message to @mdo</h5>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">�</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="form-group">
                            <label for="recipient-name" class="col-form-label">Recipient:</label>
                            <input type="text" class="form-control" id="recipient-name">
                        </div>
                        <div class="form-group">
                            <label for="message-text" class="col-form-label">Message:</label>
                            <textarea class="form-control" id="message-text"></textarea>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-success">Send message</button>
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DataTable from 'datatables.net-vue3';
    import DataTablesCore from 'datatables.net';
    import axios from 'axios'
    import Buttons from 'datatables.net-buttons';
    import $ from 'jquery';
    import Swal from 'sweetalert2'

    DataTable.use(DataTablesCore);
    DataTable.use(Buttons);

    // Load DataTables core
    //  DataTablesCore(window, $);
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    export default {
        components: {
            DataTable,
        },
        data() {
            return {
                order: [
                    [2, "desc"],

                ],
                columns: [
                   
                    { data: 'tid' },
                    { data: 'date' },
                    { data: 'amount' },
                    { data: 'narration' },
                    { data: 'method' },
                    { data: 'status' },
                    { data: 'message' },
                    { data: 'wallet' },
                    {
                        data: 'status',
                        render: function (data, type) {
                            if (type === 'display') {
                                if (data == 'Pending') { return `<div  @click="reject(${tid})" class="badge badge-outline-warning">Active</div>`; }
                                else if (data == 'Completed') { return `<div  @click="reject(${tid})" class="badge badge-outline-success">Won</div>`; }
                                else if (data == 'Rejected') { return `<div @click="reject(${tid})"  class="badge badge-outline-danger">Lost</div>`; }

                                // Create a clickable cell using the renderer

                            }
                            return data;
                        }
                    },
                   
                   
                ],
                columns2: [
                    { data: 'id' },
                    { data: 'timeg' },
                    { data: 'odds' },
                    { data: 'stake' },
                    { data: 'winnings' },
                   
                    {
                        data: 'outcomeg',
                        render: function (data, type) {
                            if (type === 'display') {
                                if (data == 'Active') { return `<div class="badge badge-outline-warning">Active</div>`; }
                                else if (data == 'Won') { return `<div class="badge badge-outline-success">Won</div>`; }
                                else if (data == 'Lost') { return `<div class="badge badge-outline-danger">Lost</div>`; }

                                // Create a clickable cell using the renderer
                               
                            }
                            return data;
                        }
                    },
                    {
                        data: 'id',
                        render: function (data, type) {
                            if (type === 'display') {
                                // Create a clickable cell using the renderer
                                return `<router-link :to="'/bets' + ${data}" @click="sendto(${data})" class="badge badge-outline-success">Settle</router-link>`;
                            }
                            return data;
                        }
                    }

                ],
                userinfo: [],
                usertrhansactions: [],
                username: null,
                url3: '',
                urls:'',
                url: 'https://posetbet.com/api/v3/usertransactions?id=' + this.$route.params.id,
                url2: 'https://posetbet.com/api/v3/userbets?id=' + this.$route.params.id,
                tableOptions: {
                    order: [[1, 'desc']],
                    rowCallback: (row, data) => {
                        // Use Vue Router's push to navigate
                        const pid = this.$route.params.id
                        $(row).on('click', () => {
                            this.$router.push(`/bet/${data.id}/${pid}`);
                        });
                    },
                },

            };

        }
        ,
        methods: {
            sendto(r) {
                this.$router.push('/bets/' + r);
            },
            handleCellClick(userId) {
                // Handle the cell click event using Vue Router's push method
                this.$router.push({ path: `/bet/${betid}/${userId}` });
            },
            handleRowClick() {
                console.log('h')
            },
            opencontainer(id) {
                $('.data-container').hide();
                $('#' + id).show();
                $('.nav-link').removeClass('active');
                $('.' + id ).addClass('active');
               
            },
            async submitForm() {
                const action = $('#action').val();
                $('#btncredit').attr('disabled');
                const amount = $('#amount').val();
                const uid = this.userinfo.ID;
                const gateway = $('#gateway').val();
                const tid = $('#tid').val();
                const narration = $('#narration').val();
                const currency = $('#currency').val();
                const balance = $('#balance').val();

                const formData = new URLSearchParams();
                formData.append('action', action);
                formData.append('amount', amount);
                formData.append('uid', uid);
                formData.append('balance', balance);
                formData.append('currency', currency);
                formData.append('gateway', gateway);
                formData.append('tid', tid);
                formData.append('narration', narration);

                try {
                    const response = await fetch('https://posetbet.com/api/v3/adjustbalances', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        body: formData
                    });

                    if (response.ok) {
                        $('#btncredit').removeAttr('disabled');
                        // Handle successful response
                        console.log('Form submitted successfully');
                        this.getuser();
                        this.getusertransactions();
                        Toast.fire({
                            icon: 'success',
                            title: 'Transaction Registered '
                        })
                    } else {
                        $('#btncredit').removeAttr('disabled');
                        // Handle error response
                        console.error('Error submitting form');
                    }
                } catch (error) {
                    console.error('An error occurred:', error);
                    $('#btncredit').removeAttr('disabled');
                }
            }
        ,
            getuser() {
                axios.get(`https://posetbet.com/api/v3/user`, {
                    params: { id: this.$route.params.id }
                })
                    .then(response => {
                        this.userinfo = response.data;
                        this.username = response.data.username;
                        this.urls = 'https://posetbet.com/api/v3/userbets?id=' + response.data.username;
                        console.log(this.url3);
                    })
                    .catch(error => {
                        console.error('Error fetching user:', error);
                    });
            },
            getusertransactions() {
                axios.get(`https://posetbet.com/api/v3/usertransactions`, {
                    params: { id: this.$route.params.id }
                })
                    .then(response => {
                        this.usertransactions = response.data;
                       
                    })
                    .catch(error => {
                        console.error('Error fetching user:', error);
                    });
            }
        },
        created() {
            
            this.getuser();
            this.getusertransactions();
            
            const self = this;

            // Add click event listener to the table for the 'cell-click' class
            this.$nextTick(() => {
                console.log('called')
                const table = $('#tableusers'); // Replace with your actual DataTable element selector
                table.on('click', '.cell-click', function () {
                    const userId = $(this).data('id');
                    self.handleCellClick(userId);
                });
            });
        },
        
        mounted() {
            const self = this;
          function  sendto(r) {
                self.$router.push('/bets/' + r);
            }
            // Add click event listener to the table for the 'cell-click' class
            this.$nextTick(() => {
                console.log('called')
                const table = $('#tablebets'); // Replace with your actual DataTable element selector
                table.on('click', '.cell-click', function () {
                    const userId = $(this).data('id');
                    self.handleCellClick(userId);
                });
            });
        },
        
    }
</script>

<style>
    .profile-navbar .nav-item .nav-link {
        color: #ffffff;
    }
        .profile-navbar .nav-item .nav-link.active {
            color: #0090e7;
        }
</style>